<template>
  <div style="padding-right: .2rem">
    <el-form ref="dialogForm" :model="dialogForm"  label-width="1rem">

      <el-form-item label="正文" prop="description">
        <div id="div1" style="width: 100%" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submit">提交</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>
<script>
// 引入 wangEditor
import wangEditor from 'wangeditor'
import {VideoCameraFilled} from "@element-plus/icons";
import {ElMessage} from "element-plus";
let formData = new FormData();
let editor = null
export default {
  name:"CaseAdd",
  components: {VideoCameraFilled},
  data() {
    return {

      typeChoice:'',
      caseId:{
        caseId:'',
      },

      editor: null,
      editorData: '',

      imgUrl:'',

      dialogForm:{
        priorityLevel:'',
      },
      caseForm:{
        caseName:'',
        typeFirst:'',
        typeSecond:'',
        details:'',
        pic:'',
      },
    }
  },
  mounted() {
    this.initEditor();
  },
  methods: {
    initEditor() {
      if (editor)
        return;
      editor = new wangEditor('#div1')
      // 自定义菜单配置
      editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'justify', // 对齐方式
        'quote', // 引用
        'image', // 插入图片
        'undo', // 撤销
        'redo' // 重复
      ]
      //检测编辑器内容的变化
      editor.config.onchange = (html) => { // 编辑器里的内容
        this.caseForm.details = html // 赋值给自己在data中定义的值
      }

      //上传图片
      //使用base64格式保存图片
      editor.config.uploadImgShowBase64 = false;
      // 配置 server 接口地址   与base64不共存
      editor.config.uploadImgServer = 'https://www.iloncloud.cn:8082/caseDetailPicUpload';
      // 设置 headers
      editor.config.uploadHeaders = {
        'Content-Type': 'multipart/form-data'
      };
      // 配置自定义参数（举例）
      /*editor.config.uploadParams = {
        token: 'abcdefg',
        user: 'wangfupeng1988'
      };*/
      editor.config.uploadFileName = 'file';
      //限制图片大小
      editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 2M
      //限制图片类型
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png'];
      //图片上传数量限制
      editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
      //等待接口的时间
      editor.config.uploadImgTimeout = 10 * 1000 //10秒
      //回调函数
      editor.config.uploadImgHooks = {
        // 上传图片之前
        before: function(xhr) {
          // console.log(xhr)
          ElMessage({
            message: '图片上传中，请稍后',
            type: 'info',
          })
          // // 可阻止图片上传
          // return {
          //   prevent: true,
          //   msg: '需要提示给用户的错误信息'
          // }
        },
        // 图片上传并返回了结果，图片插入已成功
        success: function(xhr) {
          // console.log('success', xhr)
          ElMessage({
            message: '图片上传成功',
            type: 'success',
          })
        },
        // 图片上传并返回了结果，但图片插入时出错了
        fail: function(xhr, editor, resData) {
          // console.log('fail', resData)
          ElMessage({
            message: '图片上传成功，但插入失败',
            type: 'error',
          })
        },
        // 上传图片出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          // console.log('error', xhr, resData)
          ElMessage({
            message: '图片上传失败',
            type: 'error',
          })
        },
        // 上传图片超时
        timeout: function(xhr) {
          // console.log('timeout')
          ElMessage({
            message: '图片上传超时，请检查网络连接',
            type: 'error',
          })
        },
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function(insertImgFn, result) {
          // result 即服务端返回的接口
          // console.log('customInsert', result)

          // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
          insertImgFn(result.data[0])
        }
      }

      editor.create() // 创建使用富文本
    },
    //封面

    submit() {
      console.log(this.caseForm)
    },
  },
}
</script>

<style>

/*编辑器高度*/
.w-e-text-container {
  height: 15rem !important;
  z-index: 0;
}

</style>